function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31.5"
      height="31.5"
      viewBox="0 0 31.5 31.5"
    >
      <path
        fill="#fff"
        d="M29.25 2.25H2.243A2.26 2.26 0 000 4.521v26.958a2.26 2.26 0 002.243 2.271H29.25a2.266 2.266 0 002.25-2.271V4.521a2.266 2.266 0 00-2.25-2.271zm-19.73 27H4.852V14.217h4.675V29.25zM7.186 12.164a2.707 2.707 0 112.707-2.707 2.708 2.708 0 01-2.707 2.707zM27.021 29.25h-4.669v-7.312c0-1.744-.035-3.987-2.426-3.987-2.433 0-2.805 1.9-2.805 3.86v7.439h-4.669V14.217h4.479v2.053h.063a4.917 4.917 0 014.423-2.426c4.725 0 5.6 3.115 5.6 7.165z"
        data-name="Icon awesome-linkedin"
        transform="translate(0 -2.25)"
      ></path>
    </svg>
  );
}

export default Icon;
